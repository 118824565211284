import { element } from "prop-types";

const privacy = [
	{
		question: "1. Your personal data – what is it?",
		answer: "Personal data relates to a living individual who can be identified from that data.  Identification can be by the information alone or in conjunction with any other information in the data controller’s possession or likely to come into such possession. The processing of personal data is governed by the General Data Protection Regulation (the “GDPR”).",
	},
	{
		question: "2. Who are we?",
		answer: "Bible for Life is the data controller (contact details below).  This means it decides how your personal data is processed and for what purposes.",
	},
	{
		question: "3. How do we process your personal data?",
		answer: "Bible for Life complies with its obligations under the “GDPR” by keeping personal data up to date; by storing and destroying it securely; by not collecting or retaining excessive amounts of data; by protecting personal data from loss, misuse, unauthorised access and disclosure and by ensuring that appropriate technical measures are in place to protect personal data.",
        listTitle: "We use your personal data for the following purposes:",
        listItems: ["To enable us to provide a voluntary service for the benefit of those who engage in the Bible for Life Discipleship-Coaching.",
                    "To maintain our own accounts and records",
                    "To inform interested parties of news, events and activities",
                ], 
	},
    {
		question: "4. What is the legal basis for processing your personal data?",
        listItems: ["Explicit consent for Bible for Life to hold of the data so that we can keep people informed about news, events, activities and services.",
                    "Processing is necessary for carrying out obligations under social protection law;",
                    "Processing is carried out by a not-for-profit body with a political, philosophical, religious or trade union aim provided:",
                    "the data processing relates only to contacts or former contacts of Bible for Life (or those who have regular contact with it in connection with those purposes); and",
                    "there is no disclosure to a third party without consent.",
                ], 
	},
    {
		question: "5. Sharing your personal data",
		answer: "Your personal data will be treated as strictly confidential.  We will only share your data with third parties with your consent.",
	},
    {
		question: "6. How long do we keep your personal data?",
		answer: "We will keep your records until you choose to unsubscribe unless there is a legal requirement to hold them.",
        answer2:"Specifically, we retain contact data and associated paperwork for up to 6 years after the calendar year to which they relate."
	},
    {
		question: "7. Your rights and your personal data",
		answer: "Unless subject to an exemption under the GDPR, you have the following rights with respect to your personal data:",
        answer2: "\n",
        listItems: ["The right to request a copy of your personal data which Bible for Life holds about you",
                    "The right to request that Bible for Life corrects any personal data if it is found to be inaccurate or out of date",
                    "The right to request your personal data is erased where it is no longer necessary for Bible for Life to retain such data",
                    "The right to withdraw your consent to the processing at any time",
                    "The right to request that the data controller provide the data subject with his/her personal data and where possible, to transmit that data directly to another data controller (known as the right to data portability)",
                    "The right, where there is a dispute in relation to the accuracy or processing of your personal data, to request a restriction is placed on further processing",
                    "The right to object to the processing of personal data",
                    "The right to lodge a complaint with the Information Commissioners Office",
                ], 
	},
    {
		question: "8. Further processing",
		answer: "If Bible for Life wishes to use your personal data for a new purpose, not covered by this Data Protection Notice, then Bible for Life will provide you with a new notice explaining this new use prior to commencing the processing and setting out the relevant purposes and processing conditions. Where and whenever necessary, we will seek your prior consent to the new processing.",
	},
    {
		question: "9. Contact Details",
		answer: "To exercise all relevant rights, queries of complaints please in the first instance contact Nick Crawley on nick@bibleforlife.co.uk",
        answer2:"You can contact the Information Commissioners Office on 0303 123 1113 or via email https://ico.org.uk/global/contact-us/email/ or at the Information Commissioner's Office, Wycliffe House, Water Lane, Wilmslow, Cheshire. SK9 5AF."
    },
];

export default privacy;
