import React from "react";
import fetchBooks from "../scripts/get/books";
import filterBooks from "../scripts/filterBooks";

export default () => {
	const [value, setValue] = React.useState("");
	const [books, setBooks] = React.useState([]);
	const [selection, setSelection] = React.useState([]);

	React.useEffect(() => {
		(async function () {
			let books = await fetchBooks();

			setBooks(books);
			setSelection(books);
		})();
	}, []);

	const handleInputChange = async ({ target: { value } }) => {
		setValue(value);

		let selection = await filterBooks(value, books);

		setSelection(selection);
	};

	return (
		<>
			<input value={value} onChange={handleInputChange} />
			{selection.map(({ title, image }) => (
				<div>
					<h5>{title}</h5>
					<img src={image} width={128} />
					<hr />
				</div>
			))}
		</>
	);
};
