import React from "react";
import {
  animationLength,
  Outer,
  Overlay,
  Wrapper,
  Title,
  CloseButton,
  Paragraph,
  Logo,
  ScrollWrapper,
  IconWrapper,
  SuccessIcon,
} from "./styles";
import { SubscribeForm } from "./SubscribeForm";

import MailchimpSubscribe from "react-mailchimp-subscribe";
import ReactCardFlip from "react-card-flip";

const INITIAL_DELAY = 5000;
const LINK =
  "https://bibleforlife.us4.list-manage.com/subscribe/post?u=2c2130f2ccf50cdd9f9f5bacb&amp;id=651dbac338&amp;f_id=001d2ae9f0";

export function NewsletterPerBook({
  openNewsletter,
  stateChanger,
  time,
  groupTitle,
  title,
  body,
}) {
  const [exiting, setExiting] = React.useState(false);
  const [hidden, setHidden] = React.useState(true);
  const [success, setSuccess] = React.useState(false);

  React.useEffect(() => {
    if (window.localStorage) {
      const resetDate = window.localStorage.getItem("modal-reset-date");
      const subscribed = window.localStorage.getItem("subscribed");
      if (
        ([null, undefined, ""].includes(resetDate) ||
          +resetDate < Date.now()) &&
        [null, undefined, ""].includes(subscribed)
      ) {
        window.setTimeout(() => setHidden(false), INITIAL_DELAY);
      }
    }
  }, []);

  React.useEffect(() => {
    if (openNewsletter !== "undefined") {
      if (openNewsletter) {
        setHidden(false);
      }
      // else{
      // 	if (window.localStorage) {
      // 		window.localStorage.setItem(
      // 			"modal-reset-date",
      // 			Date.now() + 2592000000
      // 		);
      // 	}
      // 	setExiting(true);
      // 	setTimeout(() => setHidden(true), animationLength);
      // 	setTimeout(() => stateChanger(false), animationLength);
      // 	setTimeout(() => setExiting(false), animationLength);
      // }
    }
  }, [openNewsletter]);

  const handleModalClose = () => {
    if (window.localStorage) {
      window.localStorage.setItem("modal-reset-date", Date.now() + 2592000000);
    }

    setExiting(true);
    setTimeout(() => setHidden(true), animationLength);
    setTimeout(() => stateChanger(false), animationLength);
    setTimeout(() => setExiting(false), animationLength);
  };

  const handleSuccess = () => {
    // Flip icon
    setSuccess(true);

    // Automatically closes after 2 seconds
    setTimeout(handleModalClose, 2000);
  };
  return (
    <Outer hidden={hidden || false} id="newsLetter">
      <Overlay exiting={exiting} onClick={handleModalClose} />
      <ScrollWrapper>
        <Wrapper exiting={exiting}>
          <CloseButton onClick={handleModalClose} />
          <Title>{title}</Title>
          <Paragraph>
            {success ? "Thanks for subscribing!" : `${body}`}
          </Paragraph>
          <MailchimpSubscribe
            url={LINK}
            render={({ subscribe, status, message }) => (
              <React.Fragment>
                <SubscribeForm
                  status={status}
                  groupTitle={groupTitle}
                  message={message}
                  onSubmit={(formData) => subscribe(formData)}
                  onSuccess={handleSuccess}
                />
              </React.Fragment>
            )}
          />

          <IconWrapper>
            <ReactCardFlip isFlipped={success} flipDirection="horizontal">
              <Logo />
              <SuccessIcon />
            </ReactCardFlip>
          </IconWrapper>
        </Wrapper>
      </ScrollWrapper>
    </Outer>
  );
}
