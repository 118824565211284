const faqs = [
	{
		question: "Is the material free?",
		answer: "Yes! All the website materials are completely free. If you would like to give a gift in appreciation, please join us giving to the charities that BfL supports each month.",
	},
	{
		question: "I’m new to this, where is the best place to start?",
		answer: "Choose a Bible book – perhaps one of your favourites – look at the ‘Meal Plan’, and start engaging in the material in a way that looks stimulating and best suits you.",
	},
	{
		question: "I find reading the Bible very hard what shall I do?",
		answer: "Since reading the Bible is better than not reading it, chose a Bible book and engage with the media in the first stage, ‘what you need to know’, follow the advise given in the ‘tools’ at the bottom of the page. As you read the Bible book take note of the ways of engagement that help you most and use these with other Bible books.",
	},
	{
		question: "I have some in-depth questions about the Bible, what shall I do?",
		answer: "Study the ‘Verse by verse’ media in ‘Stage 2’. If you still have questions you might want to refer to the resources in the ‘Best resources’, or, you are welcome to email: < info@bibleforlife.co.uk > We shall try and do our best but we cannot promise to answer every question, nor do we have the time to enter into lengthy discussions.",
	},
	{
		question: "Can I use the material to teach others in my Bible study or another context?",
		answer: "Yes, please do. The ‘Living it’ and ‘Making Disciples’ stages have different material to help you to do this, as well as lots of ideas about how you can use the BfL material to teach and help others.",
	},
	{
		question: "I’ve heard about the “PODs” with Nick, how can I do this?",
		answer: "“Pods” are coaching sessions talking about your engagement with a Bible book and usually take place on Zoom. Nick runs these with a number of groups and people. The purpose is to coach you in your engagement with the Bible so you discover and learn how to get to the heart of each Bible book, understand its message and learn how to live it out as a disciple in the kingdom.",
	},
];

export default faqs;
