import {
	BlockTitle,
	ModuleDescription,
	ModuleTitle,
	StoryParagraph,
	TeamFullName,
} from "../../../../components/text";

import breakpoint from "../../../../breakpoint";
import styled from "styled-components";

export const Wrapper = styled.header`
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	background-color: rgba(244, 241, 238, 1);
`;

export const PageTitleWrapper = styled.div`
	grid-column: 1 / -1;
	padding: 0;
	margin: 52px 0 20px 0;

	${breakpoint("md")`
		margin: 52px 0;
    `};

	${breakpoint("lg")`
        margin: 60px 0; 
		grid-column: 1 / 8;
	`}
`;

export const SliceSection = styled.section`
	margin: 0;
	padding: 0;
`;

export const PageTitle = styled(ModuleTitle)`
	color: #111112;
	margin: 0;

	${breakpoint("md")`
		// margin-bottom: 53px;
    `};

	${breakpoint("lg")`
		// margin-bottom: 53px;
    `};
`;

export const PageDescriptionWrapper = styled.div`
	/* margin-bottom: 27px; */
	grid-column: 1 / -1;

	${breakpoint("md")`
		// margin-bottom: 52px;
    `};

	${breakpoint("lg")`
        grid-column: 1 / 8;
		// margin-bottom: 60px;
    `};
`;

export const Description = styled.div`
	color: #111112;

	font-family: "Cerebri Sans";
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;

	padding: 0;
	margin: 0;
	margin-bottom: 27px;

	${breakpoint("md")`
        font-size: 28px;
        line-height: 33px;
		margin-bottom: 52px;
    `};

	${breakpoint("lg")`
        font-size: 28px;
        line-height: 33px;
		margin-bottom: 60px;
    `};
`;

export const ExtaDescription = styled.div`
	color: #111112;

	font-family: "Cerebri Sans";
	font-style: normal;
	font-weight: normal;

	font-size: 16px;
	line-height: 21px;

	padding: 0;
	margin: 0;
	margin-bottom: 20px;

	${breakpoint("md")`
        font-size: 22px;
        line-height: 29px;
        margin-bottom: 60px;
    `};

	${breakpoint("lg")`
        font-size: 22px;
        line-height: 29px;
        margin-bottom: 60px;
    `};
`;

export const SliceHeader = styled.div`
	color: #111112;

	${ModuleDescription} {
		margin-bottom: 0;
		color: #111112;
	}

	margin-bottom: 30px;

	${breakpoint("md")`
		margin-bottom: 80px;
    `};

	${breakpoint("lg")`
		margin-bottom: 105px;
    `};
`;

export const PageSection = styled(BlockTitle)`
	grid-column: 1 / -1;
	padding: 0;
	padding-bottom: 16px;
	margin: 0;

	${breakpoint("md")`
        font-size: 54px;
        line-height: 63px;
    `};

	${breakpoint("lg")`
        font-size: 54px;
        line-height: 63px;
    `};
`;
export const Section = styled.div`
	grid-column: 1 / -1;

	padding: 0;
	padding-bottom: 20px;
	border-bottom: 1px solid #62636b;
	/* rgba(17, 17, 18, 0.5); */
	margin: 0;
	margin-bottom: 40px;

	${breakpoint("md")`
		padding-bottom: 40px;
        margin-bottom: 80px;
    `};

	${breakpoint("lg")`
		padding-bottom: 40px;
        margin-bottom: 180px;
    `};
`;

export const ChapterNumber = styled.h2`
	grid-column: 1 / -1;
	color: #111112;

	font-family: "Cerebri Sans";
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;

	padding: 0;
	margin: 0;
	margin-bottom: 26px;

	${breakpoint("md")`
        font-size: 40px;
        line-height: 37px;  
        margin-bottom: 25px;
    `};

	${breakpoint("lg")`
		grid-column: 1 / 4;
        font-size: 40px;
        line-height: 37px;
    `};
`;

export const ChapterDescription = styled.div`
	grid-column: 1 / -1;

	font-family: "Cerebri Sans";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;

	padding: 0;
	margin: 0;
	margin-bottom: 30px;

	h5 {
		font-weight: bold;
		font-size: 13px;
		line-height: 15px;
		padding: 0;
		margin-bottom: 8px;
	}

	${breakpoint("md")`
        font-size: 22px;
        line-height: 28px; 
        margin-bottom: 23px;

        h5{
            font-size: 24px;
            line-height: 28px; 
            padding: 0;
            margin-bottom: 30px;
        }
    `};

	${breakpoint("lg")`
		grid-column: 5 / -1;
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 30px;
        h5{
            font-size: 24px;
            line-height: 28px; 
            padding: 0;
            margin-bottom: 30px;
        }
    `};
`;
export const HeadroomWrapper = styled.div`
	margin: 0;
	padding: 0;
`;

export const DropDownButtonWrapper = styled.div`
	max-width: 1192px;

	margin: 0 10px;
	margin-top: 10px;
	text-align: right;
	button {
		padding: 8px 20px;
		max-width: 200px;
	}

	${breakpoint("md")`
        margin: 0 25px;
        margin-top: 20px;
        button{
            padding: 15px 20px;
        }
    `};
	${breakpoint("lg")`
        margin: 0 auto;
        button{
            padding: 15px 20px;
        }
    `};
`;

export const DropDownIcon = styled.img.attrs({
	src: require("../../../../assets/icons/DropDown.svg").default,
})`
	width: 13px;
	height: 10px;

	vertical-align: bottom;

	${breakpoint("md")`
		width: 16px;
        height: 10px;
    `};
	${breakpoint("lg")`
        width: 16px;
        height: 10px;
    `};
`;

export const SummaryIcon = styled.img.attrs({
	src: require("../../../../assets/icons/ChunkSummary.png"),
})`
	height: 55px;
	margin-right: 21px;
	text-align: center;
	${breakpoint("md")`
		height: 65px`}
`;

export const SliceSummary = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;

	background-color: #dfd8d1;
	padding: 20px;
	margin: 0 0 40px 0;
	border-radius: 13px;

	p {
		font-family: "Cerebri Sans";
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		padding: 0;
		margin: 0;

		color: #111112;
		list-style-type: circle;
	}

	${breakpoint("md")`
		margin: 20px 0 60px 0;
        p {
            font-size: 18px;
            line-height: 26px;
        }
    `};
	${breakpoint("lg")`
        margin: 10px 30px 60px 30px;
        p {
            font-size: 18px;
            line-height: 26px;
        }
    `};
`;

export const ChunkWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	margin: 0;
	column-gap: 10px;
	row-gap: 10px;

	h3 {
		grid-column: 1 / -1;

		font-family: "Cerebri Sans";
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 21px;

		margin: 0;
		padding: 0;
		margin-bottom: 26px;

		color: #111112;

		a {
			cursor: pointer;
			text-decoration: none;
			color: #111112;
		}
		a:target,
		a:visited,
		a:active,
		a:hover {
			cursor: pointer;
			scroll-margin-top: 30px;
		}
	}

	h4 {
		grid-column: 1 / -1;

		font-family: "Cerebri Sans";
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		line-height: 15px;

		padding: 0;
		margin: 0;
		margin-bottom: 8px;

		color: #111112;

		a {
			cursor: pointer;
			text-decoration: none;
			color: #111112;
		}
		a:target,
		a:visited,
		a:active,
		a:hover {
			cursor: pointer;
			scroll-margin-top: 30px;
		}
	}

	h5 {
		grid-column: 1 / -1;

		font-family: "Cerebri Sans";
		font-style: normal;
		font-weight: bold;
		font-size: 13px;
		line-height: 15px;

		padding: 0;
		margin: 0;
		margin-bottom: 8px;

		color: #111112;
	}

	h6 {
		grid-column: 1 / -1;

		font-family: "Cerebri Sans";
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 21px;

		color: #111112;

		padding: 0;
		margin: 0;
		margin-bottom: 30px;
	}

	p,
	ul,
	table,
	#text {
		grid-column: 1 / -1;

		font-family: "Cerebri Sans";
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		padding: 0;
		margin: 0;
		margin-bottom: 30px;

		color: #62636b;
		list-style-type: circle;
		li {
			margin: 15px;
		}
		li::marker {
			font-size: 16px;
		}
	}

	table {
		overflow: auto;
		width: 100%;

		display: block;
		/* max-height: calc(100vh - 80px); */
		padding-bottom: 20px;
	}
	tr,
	td,
	th {
		border: 1px solid #555;
		padding: 7px;
		min-width: 200px;
	}

	${breakpoint("md")`

        h3{
            grid-column: 1 / 3;

            font-size: 40px;
            line-height: 37px;
            margin-bottom: 25px;
        }

        h4{
            grid-column: 1 / 2;
            font-size: 24px;
            line-height: 28px;
        }

        h5{
            grid-column: 1 / -1;

            font-size: 24px;
            line-height: 28px;
            margin-bottom: 30px;
        }

        h6{
            grid-column: 1 / -1;

            font-weight: normal;
            font-size: 22px;
            line-height: 29px;
            margin-bottom: 23px;
        }
        
        
        p, ul, table{
            grid-column: 2 / -1;
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 16px; 
            li{
                margin-bottom: 20px;
            }
            li::marker{
                font-size: 26px;
            }    
            
        }
        
    `}

	${breakpoint("lg")`
		box-sizing: border-box;
		padding: 30px;
		column-gap: 20px;
		max-width: 1252px;
		row-gap: 20px;
		margin: 0;
        grid-template-columns: repeat(12, 1fr);

        h3{
            grid-column: 1 / 4;
            font-size: 40px;
            line-height: 37px;
            margin: 0;
        }

        h4{
            grid-column: 3 / 5;
            font-size: 24px;
            line-height: 28px;
        }

        h5{
            grid-column: 5 / -1;
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 30px;
        }

        h6{
            grid-column: 5 / -1;
            font-weight: normal;
            font-size: 22px;
            line-height: 29px;
            margin-bottom: 30px;
        }

        p, ul, table{
            grid-column: 5 / -1;
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 26px;
            li{
                margin-bottom: 20px;
            }
            li::marker{
            font-size: 26px;
            }
        }
        
        
    `}
`;

// export const Content = styled.div`
//     grid-column: 1 / -1;
//     font-family: 'Cerebri Sans';
//     font-style: normal;
//     font-weight: normal;
//     font-size: 12px;
//     line-height: 16px;

//     padding: 0;
//     margin: 0;
//     margin-bottom: 30px;

//     color: #62636B;

//     h4{
//         font-weight: bold;
//         font-size: 13px;
//         line-height: 15px;
//         margin-bottom: 8px;
//         color: #111112;
//     }

//     ${breakpoint("md")`
// 		grid-column: 2 / -1;
//         font-size: 18px;
//         line-height: 26px;
//         margin-bottom: 16px;
//         h4{
//             grid-column: 1 / 2;
//             font-size: 24px;
//             line-height: 28px;
//         }
//     `};

//     ${breakpoint("lg")`
// 		grid-column: 5 / -1;
//         font-size: 18px;
//         line-height: 26px;
//         margin-bottom: 26px;
//         h4{
//             grid-column: 1 / 2;
//             font-size: 24px;
//             line-height: 28px;
//         }
//     `};
// `;
