import { Swiper, SwiperSlide } from "swiper/react";
import Footer from "../components/Footer";
import "../pages/Book/custom.css";

import * as Styles from "./Book/styles";
import faqs from "../data/faqs";
import privacy from "../data/privacy"

import Grid from "../components/Grid";
import React from "react";
import {useLocation} from "react-router-dom";
import {
	Wrapper,
    PageTitleWrapper,
    PageTitle,
    PageDescription,
    PageSection,
    ChapterTitle,
    Section,
    Content,
} from "./Book/modules/Text/styles";
export default () => {
	return (
		<React.Fragment>
			<Styles.Wrapper
				id="moduleFooter"
				 light>
				<Styles.ContentWrapper>
						<Wrapper style={{marginTop: 0}}>
							<Grid>
							<PageTitleWrapper id="faqs" style={{gridColumn:"1/-1", paddingTop:"60px"}}>
								<PageTitle>FAQs</PageTitle>
							</PageTitleWrapper>
							<React.Fragment>
								{faqs.map(faq => (
									<Section>
										<Grid fullwidth style={{padding: 0}}> 
											<ChapterTitle>{faq.question}</ChapterTitle>
											<Content>
												{faq.answer}
											</Content>
										</Grid>
									</Section>
								))}
							</React.Fragment>

							<PageTitleWrapper id="privacy-statement" style={{gridColumn:"1/-1", paddingTop:"50px"}}>
								<PageTitle>Privacy Statement</PageTitle>
							</PageTitleWrapper>
							<React.Fragment>
								{privacy.map(p => (
									<Section>
										<Grid fullwidth style={{padding: 0}}> 
											<ChapterTitle>{p.question}</ChapterTitle>
											<Content>
												{p.answer}
												{p.answer2 ? (
													<React.Fragment>
														<br /><br />
														{p.answer2}
													</ React.Fragment>
												):("")}

												{p.listTitle ? (
													<React.Fragment>
														<br /><br />
														{p.listTitle}
														<br /><br />
													</ React.Fragment>
												):("")}
												
												{p.listItems ? (
													
													<ul>
													{p.listItems.map(i => (
														<li>{i}</li>
													))}
													</ul>
												):("")}
											</Content>
										</Grid>
									</Section>
								))}
							</React.Fragment>
							<PageTitleWrapper id="copyright" style={{gridColumn:"1/-1", paddingTop:"50px"}}>
								<PageTitle>Copyright</PageTitle>
							</PageTitleWrapper>
							<React.Fragment>
									<Section>
										<Grid fullwidth style={{padding: 0}}> 
											<Content>
											Scriptures taken from the Holy Bible, New International Version ®, NIV ® Copyright © 1973, 1978, 1984, 2011 by Biblica, Inc.
											<br /> <br />
											Used with permission. All rights reserved worldwide.
											<br /> <br />
											The “NIV”, “New International Version”, “Biblica”, “International Bible Society” and the Biblica Logo are trademarks registered in the United States Patent and Trademark Office by Biblica, Inc. Used with permission.
											<br /> <br />
											All other content if not otherwise stated is  ©2021 Bible for Life
											</Content>
										</Grid>
									</Section>
							</React.Fragment>	
						</Grid>
					</Wrapper>
				</Styles.ContentWrapper> 
			</Styles.Wrapper> 
			<Footer />
		</React.Fragment>
	);
};
