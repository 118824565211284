import { BrowserRouter, Route } from "react-router-dom";
import About from "./pages/About";
import Book from "./pages/Book";
import BookSummary from "./pages/BookSummary";
import CarouselTest from "./pages/CarouselTest";
import Home from "./pages/Home";
import Nav from "./components/Nav";
import Prayer from "./pages/Book/modules/Prayer";
import SearchTest from "./pages/SearchTest";
import NutsAndBolts from "./pages/NutsAndBolts";
import Text from "./pages/Book/modules/Text";
import VerseByVerse from "./pages/Book/modules/VerseByVerse";
import GAListener from "./GAListener";
import Loading from "./components/Loading";
import React, { useEffect } from "react";
import { useGlobalState } from "./scripts/useGlobalState";

function App() {
  const [loading, setLoading] = useGlobalState("loading");
  React.useEffect(() => {
    document.body.style.overflow = loading ? "hidden" : "visible";
  }, [loading]);
  return (
    <BrowserRouter>
      <Nav />
      <Loading loading={loading} />
      <GAListener>
        <Route path="/nutsandbolts" component={NutsAndBolts} />
        <Route exact path="/" component={Home} />
        <Route path="/searchtest" component={SearchTest} />
        <Route path="/about" component={About} />
        <Route path="/carouseltest" component={CarouselTest} />
        <Route exact path="/book/:bookSlug" component={BookSummary} />
        <Route
          exact
          path="/book/:bookSlug/:courseSlug/:moduleSlug"
          component={Book}
        />
      </GAListener>
    </BrowserRouter>
  );
}

export default App;
