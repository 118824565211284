const data = [
	{
		address: "Pastor of Theology, St Aldate’s Oxford",
		name: "Simon Ponsonby",
		text:
			"This is a superb resource helping us open, understand and live out God’s great word to us. I cannot reccommend this highly enough.",
	},
	{
		address: "Host at Anglecon",
		name: "Bishop Lee Rayfield",
		text:
			"Nick Crawley has two great passions: helping all kinds of people recognise the God who meets us in Jesus Christ, and enabling deep and transformational encouters with Him.",
	},
	{
		address: "Pastor of Theology, St Aldate’s Oxford",
		name: "Bishop Bogus",
		text:
			"This is a superb resource helping us open, understand and live out God’s great word to us. I cannot reccommend this highly enough.",
	},
	{
		address: "Anglecon Bishop of Swindon",
		name: "St. Notreal",
		text:
			"Nick Crawley has two great passions: helping all kinds of people recognise the God who meets us in Jesus Christ, and enabling deep and transformational encouters with Him.",
	},
	{
		address: "Pastor of Theology, St Aldate’s Oxford",
		name: "Pastor Farce",
		text:
			"This is a superb resource helping us open, understand and live out God’s great word to us. I cannot reccommend this highly enough.",
	},
	{
		address: "Anglecon Bishop of Swindon",
		name: "Donna Trump",
		text:
			"Nick Crawley has two great passions: helping all kinds of people recognise the God who meets us in Jesus Christ, and enabling deep and transformational encouters with Him.",
	},
	{
		address: "Pastor of Theology, St Aldate’s Oxford",
		name: "Pope Whenedict",
		text:
			"This is a superb resource helping us open, understand and live out God’s great word to us. I cannot reccommend this highly enough.",
	},
	{
		address: "Pastor of Theology, St Aldate’s Oxford",
		name: "Simon Ponsonby",
		text:
			"This is a superb resource helping us open, understand and live out God’s great word to us. I cannot reccommend this highly enough.",
	},
	{
		address: "Host at Anglecon",
		name: "Bishop Lee Rayfield",
		text:
			"Nick Crawley has two great passions: helping all kinds of people recognise the God who meets us in Jesus Christ, and enabling deep and transformational encouters with Him.",
	},
	{
		address: "Pastor of Theology, St Aldate’s Oxford",
		name: "Elder Baloney",
		text:
			"This is a superb resource helping us open, understand and live out God’s great word to us. I cannot reccommend this highly enough.",
	},
	{
		address: "Host at Anglecon",
		name: "St. Notreal",
		text:
			"Nick Crawley has two great passions: helping all kinds of people recognise the God who meets us in Jesus Christ, and enabling deep and transformational encouters with Him.",
	},
	{
		address: "Pastor of Theology, St Aldate’s Oxford",
		name: "Pastor Farce",
		text:
			"This is a superb resource helping us open, understand and live out God’s great word to us. I cannot reccommend this highly enough.",
	},
	{
		address: "Host at Anglecon",
		name: "Donald Trump",
		text:
			"Nick Crawley has two great passions: helping all kinds of people recognise the God who meets us in Jesus Christ, and enabling deep and transformational encouters with Him.",
	},
	{
		address: "Archbishop of the Holy Roman Empire",
		name: "Pope Whenedict II",
		text:
			"This is a superb resource helping us open, understand and live out God’s great word to us. I cannot reccommend this highly enough.",
	},
];

export default data;
