import Carousel from "../components/Carousel";
import { MainWrapper } from "../components";
import React from "react";
import data from "../data/testimonials";

export default () => (
	<Carousel>
		{data.map(({ address, name, text }) => (
			<Carousel.Testimonial
				role={address}
				title={name}
				body={text}
				link="google.com"
			/>
		))}
	</Carousel>
);
